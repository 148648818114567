import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const ImageButton = styled(ButtonBase)(({ theme, height }) => ({
  height: height,
  width: "100%",
  border: `1px solid #393E41`,
  borderRadius: "15px",
  boxShadow: "0px 0px 3px 3px rgba(0,0,0,0.2)",
  overflow: "hidden",
}));

const GridItemIcon = styled("div")(({ theme, size }) => ({
  width: size,
  height: size,
  svg: {
    width: size,
    height: size,
  },
}));

const GridItemImage = styled("div")(({ theme, image }) => ({
  width: "100%",
  height: 155,
  marginTop: -24,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

const GridItemImageContain = styled("div")(({ theme, image }) => ({
  width: "100%",
  height: 105,
  marginTop: 25,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

const ListItemImage = styled("div")(({ theme, image }) => ({
  width: 40,
  height: 40,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

const ListItemImageContain = styled("div")(({ theme, image }) => ({
  width: 40,
  height: 40,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

export const ItemGridButton = ({
  small,
  href,
  text,
  icon,
  image,
  useImageContain,
  useImage,
}) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <ImageButton
        component={Link}
        to={href}
        focusRipple
        height={small ? 150 : 200}
      >
        <Grid
          container
          rowSpacing={3}
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {useImage && useImageContain && (
            <GridItemImageContain image={image} />
          )}
          {useImage && !useImageContain && <GridItemImage image={image} />}
          {!useImage && (
            <Grid item>
              <GridItemIcon size={small ? 75 : 100}>{icon}</GridItemIcon>
            </Grid>
          )}
          <Grid item>
            <Typography sx={{ fontSize: small ? "0.8em" : "1em" }}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </ImageButton>
    </Box>
  );
};

export const ItemListButton = ({
  href,
  text,
  icon,
  image,
  useImage,
  useImageContain,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={href}
        sx={{
          borderBottom: `1px solid rgba(0, 0, 0, 0.3)`,
          padding: "16px",
        }}
      >
        <ListItemIcon>
          {useImage && useImageContain && (
            <ListItemImageContain image={image} />
          )}
          {useImage && !useImageContain && <ListItemImage image={image} />}
          {!useImage && <GridItemIcon size={40}>{icon}</GridItemIcon>}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};
